import { defineStore } from 'pinia'
import http from '~/http-common'
import type { manamiruChannel } from '~~/types/manamiruChannel'
import type { GetChannelListRes } from '~~/types/getChannelListRes'
import type { GetChannelDetailRes } from '~~/types/getChannelDetailRes'
import { API_URL } from '~/const/env'
import { ApiPath } from '~/enum/apiPath'
import { inject, ref } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

const CHANNEL_STORE_KEY = 'ChannelStore'

export const useChannelStore = defineStore(CHANNEL_STORE_KEY, () => {

  const channelDatas = ref<manamiruChannel[]>([])
  const channelCount = ref(0)

  const getChannelDatas = () => {
    return channelDatas.value
  }

  const getChannelCount = () => {
    return channelCount.value
  }

  const resetChannelList = () => {
    channelDatas.value = []
    channelCount.value = 0
  }

  const setChannelRead = (noticeIdList: string[]) => {
    noticeIdList.forEach(noticeId => {
      const channel = channelDatas.value.filter(channel => channel.id === parseInt(noticeId))[0]
      if (channel) {
        channel.readStsDiv = '1'
      }
    })
  }

  const getChannelList = async (): Promise<GetChannelListRes> => {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
      'Content-type': 'multipart/form-data',
    }
    const url = encodeURIComponent(API_URL + ApiPath.GUARD_F30419).toString()
    const path = ApiPath.CHANNEL_MODULE_GET_CHANNEL_LIST
    return http.get(path + '?url=' + url, { headers: headers })
  }

  const getChannelDetail = async (noticeId: number): Promise<GetChannelDetailRes> => {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const query = '?noticeId=' + noticeId.toString()
    const path = ApiPath.CHANNEL_MODULE_GET_CHANNEL_DETAIL
    return http.get(path + query, { headers: headers })
  }

  const loadChannelList = () => {
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadChannelList', true)

    getChannelList().then(result => {
      result.data.showList.forEach(element => {
        channelDatas.value.push({
          id: element.id,
          noticeContent: element.noticeContent,
          noticeImgPath: element.noticeImgPath == null ? '' : element.noticeImgPath,
          noticeLevDiv: element.noticeLevDiv,
          noticeTitle: element.noticeTitle,
          orgId: element.orgId,
          readStsDiv: element.readStsDiv,
          attachFilePath: '',
          codValue: '',
          openedFlg: '',
          originalFilenm: '',
          startTime: element.startTime,
          endTime: element.endTime,
          updTime: '',
        })
      })
      channelCount.value = result.data.dataCount
    }).catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    }).finally(() => {
      backgroundLoading?.('loadChannelList', false)
    })
  }

  const loadChannelDetail = async (noticeId: number) => {
    const channel = channelDatas.value.filter(channel => channel.id === noticeId)[0]
    if (!channel) return

    await getChannelDetail(noticeId).then(result => {
      channel.attachFilePath = result.data.f30420Dto.attachFilePath
      channel.codValue = result.data.f30420Dto.codValue
      channel.openedFlg = result.data.f30420Dto.openedFlg
      channel.originalFilenm = result.data.f30420Dto.originalFilenm
      channel.updTime = result.data.f30420Dto.updTime
    })
  }

  return {
    channelDatas,
    channelCount,
    getChannelDatas,
    getChannelCount,
    resetChannelList,
    setChannelRead,
    loadChannelList,
    loadChannelDetail,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})
